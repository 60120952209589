import { loadJsonFileIntoSessionStorage, addClass, removeClass, getQueryParamsArray } from '../../tools/helpers';

let filterArr = [];

class ContentHubFilter {
  constructor(options = {}) {
    this.element = options.element;
    this.articlesLabelSingular = this.element.dataset.articlesLabelSingular || 'Beiträge';
    this.articlesLabelPlural = this.element.dataset.articlesLabelPlural || 'Beitrag';
    this.allTopicsLabel = this.element.dataset.allTopicsLabel || 'in allen Themen';
    this.storiesJsonURL = this.element.dataset.storiesJsonPath;
    this.FILTER_STATE_LABEL = 'filterState';
    this.STORY_JSON_LABEL = this.element.dataset.storyJsonLabel;
    const useCacheWhileFetching = this.element.dataset.useStorage;
    this.ALWAYS_FETCH_WITHOUT_CACHE = (useCacheWhileFetching != null && useCacheWhileFetching === 'false');
    this.allFilterButtonDesktop = this.element.querySelector('#select-all-isDesktop.category-filter');
    this.allFilterButtonMobile = this.element.querySelector('#select-all-isMobile.category-filter');

  }

  init() {
    const filterListDesktop = this.element.querySelectorAll('.isDesktop');
    const filterListMobile = this.element.querySelectorAll('.isMobile');
    const confirmFilterButton = this.element.querySelector('.filter-modal__confirm-btn');
    const closeModalButton = this.element.querySelector('.close-btn');
    const openModalButton = this.element.querySelector('.filter-modal__opener-btn');
    const filterModal = this.element.querySelector('.filter-modal');

    let activateAllArticlesChip = false;

    const queryCategories = getQueryParamsArray('filter');
    if (queryCategories != null && queryCategories.length > 0) {
      if (!queryCategories.includes('all')) {
        this.setFilterBasedOnQueryParameters(queryCategories);
      }

      if (filterArr.length === 0) {
        this.getCountOfAllArticles().then(articleCount => this.setArticleCountInHTML(articleCount));
        activateAllArticlesChip = true;
      }
    } else {
      const loadedFilterState = sessionStorage.getItem(this.FILTER_STATE_LABEL);

      if (loadedFilterState) {
        const filterListDesktopAndMobile = this.element.querySelectorAll('.isDesktop, .isMobile');
        this.parseLoadedFilterState(loadedFilterState, filterListDesktopAndMobile);
        this.setFilterSelectionInHTML(filterArr);
        this.setQueryParametersFromFilters();
        this.changeArticleCount(filterArr).then(articleCount => this.setArticleCountInHTML(articleCount));

      } else {
        this.getCountOfAllArticles().then(articleCount => this.setArticleCountInHTML(articleCount));
        activateAllArticlesChip = true;
      }
    }

    this.setActiveStateInSelectAllButtons(activateAllArticlesChip);

    // add event listeners
    this.filterElements(filterListDesktop, this.allFilterButtonDesktop, activateAllArticlesChip);
    this.filterElements(filterListMobile, this.allFilterButtonMobile, activateAllArticlesChip);

    openModalButton.addEventListener('click', () => {
      filterModal.classList.remove('hide-element');
      addClass(document.body, 'no-scroll');
      sessionStorage.setItem('activeFilterModal', JSON.stringify(filterArr));
    });

    closeModalButton.addEventListener('click', () => {
      const activeFilters = JSON.parse(sessionStorage.getItem('activeFilterModal'));
      this.setActiveIDs(activeFilters);

      if (!filterArr.length) {
        this.getCountOfAllArticles().then(articleCount => this.setArticleCountInHTML(articleCount));
        this.setActiveStateInSelectAllButtons(true);
        this.emptyFilterStateInSessionStorage();
      } else {
        this.setActiveStateInSelectAllButtons(false);
        this.setQueryParametersFromFilters();
        this.changeArticleCount(filterArr).then(articleCount => this.setArticleCountInHTML(articleCount));
        this.setFilterStateInSessionStorage(filterArr);
      }

      this.setFilterSelectionInHTML(filterArr);
      filterModal.classList.add('hide-element');
      removeClass(document.body, 'no-scroll');
    });

    confirmFilterButton.addEventListener('click', () => {
      filterModal.classList.add('hide-element');
      removeClass(document.body, 'no-scroll');
    });
  }

  setFilterBasedOnQueryParameters(queryCategories) {
    const filterCollectionNode = document.getElementsByClassName('filtercollection')[0];
    const buttonHtmlCollection = filterCollectionNode.getElementsByTagName('button');

    for (let j = 0; j < buttonHtmlCollection.length; j += 1) {
      const buttonNode = buttonHtmlCollection[j];
      const namePartOfId = buttonNode.dataset.id.substring(0, buttonNode.dataset.id.indexOf('-isDesktop'));

      // filter out select-all button
      if (!namePartOfId.includes('-')) {
        // correct encoding or special characters will be wrong
        const correctlyEncodedId = decodeURIComponent(atob(namePartOfId)
          .split('')
          .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
          .join(''));

        for (let i = 0; i < queryCategories.length; i += 1) {
          if (correctlyEncodedId.toUpperCase() === queryCategories[i].toUpperCase()) {
            this.activateFilterButton(buttonNode, buttonHtmlCollection.length);
            break;
          }
        }
      }
    }
  }

  setActiveIDs(IDList) {
    const dataIDs = IDList.map(item => item.data.id);

    const categoryButtons = document.querySelectorAll('.category-filter.isMobile');
    categoryButtons.forEach((entry) => {
      const dataID = entry.getAttribute('data-id');

      if (!dataIDs.includes(dataID) && !dataIDs.includes(this.getSiblingIdFromId(dataID))) {
        entry.classList.remove('isActive');
        this.removeFilterFromArr(entry.dataset.id);
        this.changeActiveStateInSibling(entry.dataset.id, false);
      } else {
        entry.classList.add('isActive');

        this.changeActiveStateInSibling(entry.dataset.id, true);
        const activeElement = { html: entry, data: entry.dataset };
        const itemIsIncludedInFilterArr = filterArr.some(item => item.data.id === activeElement.data.id);
        if (!itemIsIncludedInFilterArr) {
          filterArr.push(activeElement);
        }
      }
    });
  }

  parseLoadedFilterState(loadedFilterState, filterList) {
    JSON.parse(loadedFilterState).forEach((element) => {
      filterList.forEach((button) => {
        if (element.data.id === button.dataset.id) {
          this.setToOrRemoveFromFilterArrDependingOnClassAtElement(button, 'isActive');
          this.changeActiveStateInSibling(element.data.id, true);
        }
      });
    });
  }

  filterElements(filterList, allFilterButton, activateAllArticlesChip) {
    filterList.forEach((element) => {

      if (activateAllArticlesChip && (element.dataset.id === 'select-all-isMobile' || element.dataset.id === 'select-all-isDesktop')) {
        element.classList.add('isActive');
      }

      element.addEventListener('click', () => {
        this.activateFilterButton(element, filterList.length);

        this.setQueryParametersFromFilters();
      });
    });
  }

  activateFilterButton(element, filterListLength) {
    this.changeActiveStateInSibling(element.dataset.id, true);

    // check if "all" is selected
    if (element.dataset.id === 'select-all-isMobile' ||
      element.dataset.id === 'select-all-isDesktop') {
      this.deselectAllFilters('isActive');
      this.getCountOfAllArticles().then(articleCount => this.setArticleCountInHTML(articleCount));
      this.setActiveStateInSelectAllButtons(true);
      this.emptyFilterStateInSessionStorage();

      // check if a specific filter is selected
    } else {
      this.handleSelectedFilter(element, 'isActive');
      this.changeArticleCount(filterArr).then(articleCount => this.setArticleCountInHTML(articleCount));
      this.setActiveStateInSelectAllButtons(false);
      this.setFilterStateInSessionStorage(filterArr);
    }

    // check if all single filters are selected
    if (filterListLength - 1 === filterArr.length) {
      // sets filterArr to []
      this.deselectAllFilters('isActive');
      this.setActiveStateInSelectAllButtons(true);
      this.emptyFilterStateInSessionStorage();
    }

    // check if no single filter is selected
    if (!filterArr.length) {
      this.getCountOfAllArticles().then(articleCount => this.setArticleCountInHTML(articleCount));
      this.setActiveStateInSelectAllButtons(true);
      this.emptyFilterStateInSessionStorage();
    }

    if (filterArr.length && filterListLength - 1 > filterArr.length) {
      this.setActiveStateInSelectAllButtons(false);
    }
  }

  getSiblingIdFromId(idOfButton) {
    return idOfButton.includes('isMobile') ?
      idOfButton.replace('isMobile', 'isDesktop') :
      idOfButton.replace('isDesktop', 'isMobile');
  }

  changeActiveStateInSibling(idOfButton, isActive) {
    this.changeActiveStateOfButton(this.getSiblingIdFromId(idOfButton), isActive);
  }

  changeActiveStateOfButton(idOfButton, isActive) {
    const buttonElement = document.getElementById(idOfButton);

    if (!isActive) {
      buttonElement.classList.remove('isActive');
    } else {
      buttonElement.classList.add('isActive');
    }
  }

  handleSelectedFilter(element, className) {
    this.setToOrRemoveFromFilterArrDependingOnClassAtElement(element, className);
    this.setFilterSelectionInHTML(filterArr);
  }

  setToOrRemoveFromFilterArrDependingOnClassAtElement(element, className) {
    if (element.classList.contains(className)) {
      element.classList.remove(className);
      this.removeFilterFromArr(element.dataset.id);
    } else {
      element.classList.add(className);
      filterArr.push({ html: element, data: element.dataset });
    }
  }

  deselectAllFilters(className) {
    filterArr.forEach((filter) => {
      filter.html.classList.remove(className);
      this.changeActiveStateInSibling(filter.html.id, false);
    });

    filterArr = [];
    this.setFilterSelectionInHTML(filterArr);
  }

  removeFilterFromArr(filterId) {
    filterArr.forEach((filter, index) => {
      if (filter.data.id === filterId) {
        filterArr.splice(index, 1);
        this.changeActiveStateInSibling(filterId, false);
      } else if (filter.data.id === this.getSiblingIdFromId(filterId)) {
        // saved button and clicked button have different isDesktop and isMobile values
        filterArr.splice(index, 1);
        this.changeActiveStateOfButton(filter.data.id, false);
      }
    });
  }

  async getCountOfAllArticles() {
    if (this.ALWAYS_FETCH_WITHOUT_CACHE || !sessionStorage.getItem(this.STORY_JSON_LABEL)) {
      await this.loadStoriesFromJson();
    }

    // load json data from session storage
    const dataFromSessionStorage = sessionStorage.getItem(this.STORY_JSON_LABEL);

    let articleCount = 0;
    if (dataFromSessionStorage != null) {
      const stories = JSON.parse(dataFromSessionStorage);
      articleCount = stories.stories.length;
    }

    return articleCount;
  }

  async changeArticleCount(filterArray) {
    if (this.ALWAYS_FETCH_WITHOUT_CACHE || !sessionStorage.getItem(this.STORY_JSON_LABEL)) {
      await this.loadStoriesFromJson();
    }

    // load json data from session storage
    const dataFromSessionStorage = sessionStorage.getItem(this.STORY_JSON_LABEL);

    let articleCount = 0;
    if (dataFromSessionStorage != null) {
      const storiesJSON = JSON.parse(dataFromSessionStorage);

      articleCount = storiesJSON.stories
        .filter((story) => {
          let isFiltered = false;
          filterArray.forEach((element) => {
            isFiltered = isFiltered || story.topic.indexOf(element.data.label) >= 0;
          });
          return isFiltered;
        })
        .length;
    }

    return articleCount;
  }

  setArticleCountInHTML(count) {
    const htmlString = (count === 1) ? `${count} ${this.articlesLabelSingular}` : `${count} ${this.articlesLabelPlural}`;
    const counterElement = this.element.querySelectorAll('#articleCounter');
    counterElement[0].innerHTML = htmlString;
  }

  setFilterSelectionInHTML(selectedFiltersArr) {
    let htmlString = '';
    selectedFiltersArr.forEach((filter) => {
      const filterString = `${filter.data.label}, `;
      htmlString += filterString;
    });

    htmlString = htmlString.substring(0, htmlString.length - 2);

    htmlString = htmlString ? `in ${htmlString}` : this.allTopicsLabel;
    const selectedFilterElement = this.element.querySelectorAll('#selectedFilter');
    selectedFilterElement[0].innerHTML = htmlString;
  }

  setActiveStateInSelectAllButtons(isActive) {
    if (this.allFilterButtonDesktop || this.allFilterButtonMobile) {
      if (isActive) {
        this.allFilterButtonDesktop.classList.add('isActive');
        this.allFilterButtonDesktop.classList.add('hide-number');
        this.allFilterButtonMobile.classList.add('isActive');
        this.allFilterButtonMobile.classList.add('hide-number');
      } else {
        this.allFilterButtonDesktop.classList.remove('isActive');
        this.allFilterButtonDesktop.classList.add('hide-number');
        this.allFilterButtonMobile.classList.remove('isActive');
        this.allFilterButtonMobile.classList.add('hide-number');
      }
    }
  }

  async loadStoriesFromJson() {
    if (this.ALWAYS_FETCH_WITHOUT_CACHE && document.getElementById('stories_from_in_template_json') != null) {
      const storiesJsonInScript = document.getElementById('stories_from_in_template_json');
      sessionStorage.setItem(this.STORY_JSON_LABEL, storiesJsonInScript.innerText);
    } else {
      await loadJsonFileIntoSessionStorage(this.storiesJsonURL, this.STORY_JSON_LABEL, this.ALWAYS_FETCH_WITHOUT_CACHE);
    }
  }

  setFilterStateInSessionStorage(filterStateArray) {
    sessionStorage.setItem(this.FILTER_STATE_LABEL, JSON.stringify(filterStateArray));
  }

  emptyFilterStateInSessionStorage() {
    sessionStorage.removeItem(this.FILTER_STATE_LABEL);
  }

  setQueryParametersFromFilters() {
    const searchParams = new URLSearchParams();
    const uniqueFilterParams = new Set();

    filterArr.forEach((filter) => {
      // due to isDesktop and isMobile duplicates are a possibility
      if (!uniqueFilterParams.has(filter.data.label)) {
        searchParams.append('filter', filter.data.label);
        uniqueFilterParams.add(filter.data.label);
      }
    });

    if (searchParams.size > 0) {
      window.history.replaceState(null, null, `${window.location.pathname}?${searchParams.toString()}`);
    } else {
      window.history.replaceState(null, null, window.location.pathname);
    }
  }
}

export default ContentHubFilter;
