import { addClass, removeClass, hasClass } from '../../tools/helpers';

export const CLASSNAME_CONTENT = '.org--access-restriction__content-wrapper';
export const CLASSNAME_CONSENT = '.org--access-restriction__consent-wrapper';
export const CLASSNAME_BUTTON = '.org--access-restriction__button a';
export const CLASSNAME_CHECKBOX = '.org--access-restriction__checkbox';
export const CLASSNAME_TOOLTIP = '.org--access-restriction__tooltip-text';

class AccessRestriction {
  constructor(options = {}) {
    this.element = options.element;
    this.content = this.element.querySelector(CLASSNAME_CONTENT);
    this.consent = this.element.querySelector(CLASSNAME_CONSENT);
    this.button = this.element.querySelector(CLASSNAME_BUTTON);
    this.checkbox = this.element.querySelector(CLASSNAME_CHECKBOX);
    this.tooltip = this.element.querySelector(CLASSNAME_TOOLTIP);
  }

  init() {
    this.checkbox.checked = false;
    addClass(this.button, 'btn-disabled');

    // timeout inserted so that the accordion can expand in the hidden part and take the right height
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => addClass(this.content, 'hidden'), 10);

    this.button.addEventListener('click', (event) => {
      event.preventDefault();
      removeClass(this.tooltip, 'hidden');

      if (!hasClass(this.button, 'btn-disabled')) {
        removeClass(this.content, 'hidden');
        addClass(this.consent, 'hidden');
      }

      this.button.blur();
    });

    this.checkbox.addEventListener('click', () => {
      if (this.checkbox.checked) {
        removeClass(this.button, 'btn-disabled');
        this.button.setAttribute('aria-disabled', 'false');
        addClass(this.tooltip, 'hidden');
      } else {
        addClass(this.button, 'btn-disabled');
      }
    });
  }
}

export default AccessRestriction;
